@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert";

.alert {
  position: relative;
  background: #fff;
  color: #a5a6af;
  background: #36384a;
  font-size: var(--text-sm);
  border: 2px solid var(--color-accent);
  box-shadow: 0 3px 20px var(--color-accent);
  border-radius: 5px;
  overflow: hidden;
  background: var(--color-bg);

  p {
    margin: 0;

    &:not(:last-child){
      margin-bottom: var(--space-md);
    }
  }

  .ico {
    line-height: var(--body-line-height);
  }

  .ico,
  a,
  button {
    color: inherit;
  }

  &__head {
    position: relative;
    padding: var(--space-md);
    background-color: var(--color-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--text-xs);
    text-transform: uppercase;
    font-weight: var(--type-weight-bold);
    letter-spacing: .042em;
  }

  &__media {
    margin-right: var(--space-md);
  }

  &__headline {
    flex-grow: 1;
    font-size: var(--text-md);
    font-weight: var(--type-weight-heading);
  }

  &__actions {
    margin-left: var(--space-md);
  }

  &__content {
    background-color: var(--color-bg-light);
    flex-grow: 1;
    overflow: auto;
    color: var(--color-text);
    padding: var(--space-md);
  }

  &.is {
    &--info {
      color: var(--color-accent);
      border-color: var(--color-accent);
      box-shadow: 0 3px 20px var(--color-accent);
    }

    &--valid {
      color: var(--color-valid);
      border-color: var(--color-valid);
      box-shadow: 0 3px 20px var(--color-valid);
    }

    &--error {
      color: var(--color-error);
      border-color: var(--color-error);
      box-shadow: 0 3px 20px var(--color-error);
    }

    &--warning {
      color: var(--color-warning);
      border-color: var(--color-warning);
      box-shadow: 0 3px 20px var(--color-warning);
    }

    &--hidden {
      display: none;
    }
  }
}
